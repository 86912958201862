export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~5],
		"/auth0/auth": [~25],
		"/auth0/logout": [~26],
		"/auth/error": [21],
		"/auth/login": [~22],
		"/auth/logout": [~23],
		"/auth/token/[token]": [~24],
		"/(cp)/cp": [~6,[2]],
		"/(cp)/cp/agent-view/conversations/[conversationId]": [~8,[2]],
		"/(cp)/cp/agent-view/[agentId]": [~7,[2]],
		"/(cp)/cp/application/[applicationId]": [~9,[2]],
		"/(cp)/cp/knowledge": [~10,[2]],
		"/(cp)/cp/quality": [~11,[2]],
		"/(cp)/cp/requests": [~12,[2]],
		"/(cp)/cp/settings": [~13,[2]],
		"/(cp)/cp/smart-agents": [14,[2]],
		"/(cp)/cp/testing-suite": [~15,[2]],
		"/(cp)/cp/wave/[waveId]": [~16,[2]],
		"/itops/administrate-ta": [~27],
		"/itops/client-accounts": [~28],
		"/itops/client-accounts/add": [~37],
		"/itops/client-accounts/edit/[clientAccountId]": [~38],
		"/itops/client-accounts/[clientAccountId]": [~29],
		"/itops/client-accounts/[clientAccountId]/lob/add": [~31],
		"/itops/client-accounts/[clientAccountId]/lob/edit/[lobId]": [~32],
		"/itops/client-accounts/[clientAccountId]/lob/[lobId]": [~30],
		"/itops/client-accounts/[clientAccountId]/wave/add": [~36],
		"/itops/client-accounts/[clientAccountId]/wave/[waveId]": [~33],
		"/itops/client-accounts/[clientAccountId]/wave/[waveId]/edit": [~34],
		"/itops/client-accounts/[clientAccountId]/wave/[waveId]/view": [~35],
		"/itops/dashboard": [~39],
		"/itops/developers/deployment-frequency": [~40,[3]],
		"/itops/developers/feature-flags": [~41,[3]],
		"/itops/developers/test-coverage": [~42,[3]],
		"/itops/flows": [~43],
		"/itops/jobs": [~44],
		"/itops/roles/add": [~45],
		"/itops/users/list": [~47],
		"/itops/users/[userId]": [~46],
		"/modals": [48,[4]],
		"/modals/add-agent": [49,[4]],
		"/modals/congratulatory": [50,[4]],
		"/modals/request-agent": [51,[4]],
		"/ta": [~52],
		"/ta/applications": [~54],
		"/ta/application/[applicationId]": [~53],
		"/ta/dashboard": [~55],
		"/ta/dashboard/job-editor": [~56],
		"/ta/dashboard/job-editor/[jobId]": [~57],
		"/ta/emails": [~58],
		"/ta/inoffice": [~59],
		"/ta/interviews": [~60],
		"/ta/jobs": [~61],
		"/ta/wave/[waveId]": [~62],
		"/xobot": [~63],
		"/[clientId]/interview/[appId]": [~17],
		"/[clientId]/poc-editor": [~18],
		"/[clientId]/talent/[lobId]": [~19],
		"/[clientId]/talent/[lobId]/[waveId]": [~20]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';